import { executeApi, useApi } from '../../composables/useApi'
import { ref } from 'vue'
import { createSharedComposable } from '@vueuse/core'
import {
  CollegeSurveyCardData,
  CollegeSurveyApiGetCollegeSurveyResult1Request,
  OpenDataApiGetCollegeSurveyResultRequest,
  ResCollegeSurveyResults,
} from '../../openapi'
import { navigateTo } from 'nuxt/app'

export default createSharedComposable(() => {
  const api = useApi()

  const collegeSurveyData = ref({})
  const collegeSurveyCheck = ref({})
  const collegeSurveyMyPreference = ref({})
  const CollegeSurveyResultViewMore = ref({})
  const collegeSurveyResultSignin = ref<CollegeSurveyCardData[]>([])

  const AnonCollegeSurvey = ref<OpenDataApiGetCollegeSurveyResultRequest>({
    reqMemberCollegeSurvey: {
      addressRegions: [],
      citySettings: [],
      studentSize: [],
      netPrice: 0,
      gpa: null,
      waiverYn: '',
      duolingoTestInfo: {
        literacyScore: 0,
        conversationScore: 0,
        comprehensionScore: 0,
        productionScore: 0,
        overallScore: 0,
      },
      toeflTestInfo: {
        readingScore: 0,
        speakingScore: 0,
        listeningScore: 0,
        writingScore: 0,
      },
      ieltsTestInfo: {
        readingScore: 0,
        speakingScore: 0,
        listeningScore: 0,
        writingScore: 0,
        overallBandScore: 0,
      },
      satRwScore: 0,
      satMathScore: 0,
      actReadingScore: 0,
      actMathScore: 0,
      actEnglishScore: 0,
      actScienceScore: 0,
      apTestScoreList: [],
      athleticsScore: 0,
      academicsScore: 0,
    },
  })

  const AnonCollegeSurveyResults = ref<ResCollegeSurveyResults>({
    reachCollege: {
      ipedsCollegeId: 0,
      ipedsCollegeName: '',
      imgUrl: '',
      satCompRange: '',
      actc: '',
      adminTest: 0,
      netPrice: 0,
      acceptRate: 0,
      weight: 0,
      userChancesInfo: { userChancesRate: 0, userChancesTitle: '' },
    },
    targetCollege: {
      ipedsCollegeId: 0,
      ipedsCollegeName: '',
      imgUrl: '',
      satCompRange: '',
      actc: '',
      adminTest: 0,
      netPrice: 0,
      acceptRate: 0,
      weight: 0,
      userChancesInfo: { userChancesRate: 0, userChancesTitle: '' },
    },
    safetyCollege: {
      ipedsCollegeId: 0,
      ipedsCollegeName: '',
      imgUrl: '',
      satCompRange: '',
      actc: '',
      adminTest: 0,
      netPrice: 0,
      acceptRate: 0,
      weight: 0,
      userChancesInfo: { userChancesRate: 0, userChancesTitle: '' },
    },
  })

  const loadAnonCollegeSurveyFromLocalStorage = () => {
    const data = localStorage.getItem('AnonCollegeSurvey')
    if (data) {
      AnonCollegeSurvey.value = JSON.parse(data)
    }
  }

  const saveAnonCollegeSurveyToLocalStorage = () => {
    localStorage.setItem('AnonCollegeSurvey', JSON.stringify(AnonCollegeSurvey.value))
  }

  const removeAnonCollegeSurveyFromLocalStorage = () => {
    localStorage.removeItem('AnonCollegeSurvey')
  }

  const fetchCollegeSurveyResultSignin = async (collegeIds: CollegeSurveyApiGetCollegeSurveyResult1Request) => {
    await executeApi(async () => {
      const { data } = await api.college.getCollegeSurveyResult1(collegeIds)
      collegeSurveyResultSignin.value = data
    })
  }

  const submitSignInCollegeSurvey = async () => {
    await executeApi(async () => {
      await api.college.saveMemberCollegeSurvey(AnonCollegeSurvey.value)
    })

  }

  const fetchAnonCollegeSurveyResult = async () => {
    await executeApi(async () => {
      const { data } = await api.openData.getCollegeSurveyResult(AnonCollegeSurvey.value)
      AnonCollegeSurveyResults.value = data
    })
  }

  const submitAnonCollegeSurveyResult = async () => {
    console.log('submitting')
    saveAnonCollegeSurveyToLocalStorage()
    navigateTo('/college/result')
  }

  const fetchCollegeSurveyResultViewMore = async () => {
    await executeApi(async () => {
      const { data } = await api.college.getCollegeSurveyResultViewMore()
      CollegeSurveyResultViewMore.value = data
    })
  }

  const fetchCollegeSurveyCheckData = async () => {
    await executeApi(async () => {
      const { data } = await api.college.getMemberCollegeSurveyCheck()
      collegeSurveyCheck.value = data
    })
  }

  const saveAnonCollegeSurvey = async () => {
    if (AnonCollegeSurvey.value.reqMemberCollegeSurvey.gpa) {
      executeApi(async () => {
        await api.college.saveMemberCollegeSurvey(AnonCollegeSurvey.value)
      })
      console.log('submitted')
      //only required value is GPA
      AnonCollegeSurvey.value.reqMemberCollegeSurvey.gpa = null
    }
  }

  const fetchCollegeSurveyMyPreference = async () => {
    await executeApi(async () => {
      const { data } = await api.college.getMemberCollegeSurvey()
      collegeSurveyMyPreference.value = data
    })
  }

  return {
    collegeSurveyData,
    collegeSurveyCheck,
    collegeSurveyMyPreference,
    collegeSurveyResultSignin,
    CollegeSurveyResultViewMore,
    AnonCollegeSurvey,
    AnonCollegeSurveyResults,
    submitSignInCollegeSurvey,
    submitAnonCollegeSurveyResult,
    removeAnonCollegeSurveyFromLocalStorage,
    saveAnonCollegeSurveyToLocalStorage,
    loadAnonCollegeSurveyFromLocalStorage,
    fetchAnonCollegeSurveyResult,
    saveAnonCollegeSurvey,
    fetchCollegeSurveyCheckData,
    fetchCollegeSurveyMyPreference,
    fetchCollegeSurveyResultSignin,
    fetchCollegeSurveyResultViewMore,
  }
})
